import { type KeyEvent } from 'types/key_event';
import { postRequest } from 'utils/rest-api-util';

export const postRegisteredKeyEvent = async (
  analyticId: string,
  keyEventParams: KeyEvent,
): Promise<Response> => {
  return await postRequest(
    `/api/v1/analytics/${analyticId}/registered_key_events`,
    keyEventParams,
  );
};
